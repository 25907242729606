export const english = {
  translation: {
    Home: 'Home',
    UserInfo: 'User Information',
    Close: 'Close',
    Save: 'Save',
    Add: 'Add',
    'Search for type': 'Search for {{searchType}}',
    'Add new type': 'Add new {{addType}}',
    'Add new task': 'Add new task',
    'Add new milestone': 'Add new milestone',
    'Add new': 'Add new',
    'Task name': 'Task name',
    Description: 'Description',
    Tasks: 'Tasks',
    Task: 'Task',
    'No tasks found': 'No tasks found',
    Login: 'Login',
    Password: 'Password',
    'Confirm password': 'Confirm password',
    'Not logged in': 'Not logged in',
    'Invalid email or password': 'Invalid email or password',
    'How to rate?': 'How to rate?',
    'Sort by': 'Sort by',
    'Your ratings': 'Your ratings',
    "Team's overall ratings": "Team's overall ratings",
    'Not yet rated': 'Not yet rated:',
    Status: 'Status',
    'Required by': 'Required by',
    'Show all': 'Show all',
    'Not rated by me': 'Not rated by me',
    'Rated by me': 'Rated by me',
    'Created by': 'Created by',
    Completed: 'Completed',
    'In progress': 'In progress',
    'Not started': 'Not started',
    'Not completed': 'Not completed',
    'Rate task': 'Rate task',
    Edit: 'Edit',
    'Close edit': 'Close edit',
    'Edit ratings': 'Edit ratings',
    'Add a comment': 'Add a comment...',
    Roadmap: 'Roadmap',
    'Roadmap View': 'Roadmap View',
    'Your projects': 'Your projects',
    'Add project': 'Add project',
    'Set as default project': 'Set as default project',
    'Delete project ellipsis': 'Delete project...',
    'Delete project': 'Delete project',
    'task list': 'task list',
    Plan: 'Plan',
    Previous: 'Previous',
    Cancel: 'Cancel',
    Confirm: 'Confirm',
    'Great!': 'Great!',
    'Overview for': 'Overview for',
    Logout: 'Logout',
    'Log out': 'Log out',
    'Log in': 'Log in',
    'Logging out': 'Logging out',
    'Ratings for': 'Ratings for',
    'Your rating': 'Your rating',
    'Your comment': 'Your comment',
    'You have not rated this task': 'You have not rated this task',
    "Team's ratings": "Team's ratings",
    'No ratings from team': 'No ratings from team',
    Rate: 'Rate',
    Title: 'Title',
    Rating: 'Rating',
    'Created on': 'Created on',
    Name: 'Name',
    Value: 'Value',
    'Average type': 'Average {{type}}',
    'Rate customer': 'Rate customer',
    milestones: 'milestones',
    Milestones: 'Milestones',
    'Milestone task instructions': 'Drag & drop tasks here',
    'Add milestone': 'Add milestone',
    'Milestone name': 'Milestone name',
    'Delete milestone': 'Delete milestone',
    'Delete milestone confirmation':
      'Do you want to delete this milestone? Any task ordered to this milestone will be kept, but they will be set to unordered state.',
    'Change milestone name': 'Change milestone name',
    Stakeholders: 'Stakeholders',
    'Average value': 'Average value',
    'Average complexity': 'Average complexity',
    'Avg Value': 'Avg. Value',
    'Avg Complexity': 'Avg. Complexity',
    'Your unrated tasks': 'Your unrated tasks',
    'Roadmap completion': 'Roadmap completion',
    Import: 'Import',
    'Import tasks from': 'Import tasks from {{name}}',
    'Unassigned tasks': 'Unassigned tasks',
    'Time Estimation': 'Time Estimation',
    'Total value': 'Total value',
    'Total complexity': 'Total complexity',
    'Estimate milestone durations': 'Estimate milestone durations',
    'Milestone to be compared to': 'Milestone to be compared to',
    'Select a milestone': 'Select a milestone',
    'Working days estimation': 'Working days estimation',
    'Unable to calculate working days estimate':
      'Unable to calculate working days estimate',
    'Predicted milestone durations': 'Predicted milestone durations',
    'Client shares': 'Client shares',
    'Set different weighting for clients':
      'Set different weighting for clients',
    'Weighting description':
      "Client weights affect the scaling of milestone's total values in roadmap graph. Weight of 0% will ignore the ratings of the client in question.",
    'Client Weights': 'Client Weights',
    register: 'register',
    'New here?': 'New here?',
    'Please fill out some info about you':
      'Please fill out some info about you.',
    'Your name': 'Your name',
    'Your email': 'Your email',
    'Create account': 'Create account',
    'Already have an account?': 'Already have an account?',
    'No account?': 'No account?',
    'Terms of use error':
      'Please read and accept the terms of use and privacy policy.',
    'Password type error': 'Password should contain at least 8 characters.',
    'Password confirmation error': 'Passwords do not match.',
    'Welcome user': '<0>Welcome, </0>{{name}}',
    'Invalid Oauth token error':
      'Invalid or missing Oauth token, please configure Oauth first.',
    'Terms of use':
      'I agree to the <1>terms of use</1> and <4> privacy policy </4>',
    'Setup OAuth for': 'Setup OAuth for {{name}}',
    'OAuth URL': 'OAuth URL:',
    'Oauth authorization link':
      'Please visit <2>this URL</2> and input the code below:',
    'Oauth token swap error':
      'Unable to swap {{name}} OAuth token. Please contact an administrator if the problem persists.',
    'Oauth url query error':
      'Unable to query {{name}} OAuth URL. Please contact an administrator if the problem persists.',
    'Select labels to import': 'Select labels to import:',
    'Select integration board': 'Select {{name}} board:',
    'Missing configuration error':
      'No {{name}} configuration found. Please configure {{name}} first.',
    'Internal server error': 'Internal server error',
    email: 'email',
    Email: 'Email',
    'Example email': '{{localPart}}@example.com',
    uniqueViolation: 'This {{field}} is already in use.',
    ValidationError: 'Invalid {{field}}.',
    'Add a client': 'Add a client',
    Step: 'Step',
    'Client info': 'Client info',
    'Select representative': 'Select representative',
    'All done roadmap description':
      '<strong>All done.</strong> You can now confirm the project creation, or go back to previous steps if you missed something.',
    'All done customer description':
      '<strong>All done.</strong> You can now confirm adding the client, or go back to previous steps if you missed something.',
    Finish: 'Finish',
    'Customer added': 'Awesome! {{customer}} is now added!',
    'Give them a name': 'Give them a name',
    'Remove client': 'Remove client',
    'Remove team member': 'Remove team member',
    'Remove invitation': 'Remove invitation',
    'No, go back': 'No, go back',
    'Yes, remove it': 'Yes, remove it',
    'Cancel customer addition warning':
      'Are you sure you want to cancel adding this client?',
    'Yes, I want to cancel': 'Yes, I want to cancel',
    'Modify client information': 'Modify client information',
    'Client name': 'Client name',
    'Contact information': 'Contact information',
    'Client color': 'Client color',
    Clients: 'Clients',
    Color: 'Color',
    Generate: 'Generate',
    'Pick a color': 'Pick a color',
    "Who's responsible for the client value ratings?":
      "Who's responsible for the client value ratings?",
    'Responsible for': 'Responsible for',
    'Remove customer warning':
      'Are you sure you want to remove <strong>{{name}}</strong>?',
    'Remove team warning':
      'Are you sure you want to remove <strong>{{name}}</strong> from the project?',
    'Remove invitation warning':
      'Are you sure you want to remove project invitation for <strong>{{name}}</strong>?',
    'Remove member explanation':
      'This won’t delete their account; only removes them from this project.',
    'Send invitation explanation':
      'Do you want to send a new invitation for <strong>{{email}}</strong> to join the project?',
    'Team members': 'Team members',
    members: 'members',
    'Add members': 'Add members',
    'Add clients': 'Add clients',
    'Add client': 'Add client',
    'The email should be unique': 'The email should be unique.',
    'The combination of name and email should be unique':
      'The combination of name and email should be unique',
    'Remove member warning':
      'If you remove this member, a new representative must be selected for the clients of this member in the next section. <1>Remove anyway</1>',
    'No representatives warning':
      'This client has no representatives: add at least one to progress',
    'Skip adding 1/3': 'Adding {{type}} at this point is optional. You can',
    'Skip adding 2/3': 'skip it',
    'Skip adding 3/3': 'if you want to.',
    'Skip warning': 'Created list will be lost if you skip. <1>Skip anyway</1>',
    'members summary': '<strong>Members:</strong>',
    'clients summary': '<strong>Clients:</strong>',
    'Add member': 'Add member',
    'Edit member': 'Edit member',
    'Add a team member': 'Add a team member',
    'Here’s how to add a team member':
      '<strong>Here’s how to add a team member.</strong> VISDOM delivers an email with impermanent, one-time-usage link, which added members can use to register.',
    'Invitation status info':
      'The invitation statuses can be checked at <2>Team page</2>',
    'Hide info': 'Hide info',
    'Member email the link will be sent to':
      'Member email the link will be sent to',
    'Send link': 'Send link',
    'Sent!': 'Sent!',
    'Modify team members': 'Modify team members',
    'Modify invitation': 'Modify invitation',
    'Member role': 'Member role',
    'Admin caution':
      '<strong>Caution:</strong> giving Admin role to this member lets them manage the project however they like.',
    Developer: 'Developer',
    Business: 'Sales representative',
    Admin: 'Admin',
    Unordered: 'Unordered',
    'Are you sure to continue': 'Are you sure you want to continue?',
    'Project deletion warning':
      'This action can’t be reverted, and all of the project information will be lost.',
    'Contact us': 'Contact us',
    'Problems in joining a project?': 'Problems in joining a project?',
    'Create a new project': 'Create a new project',
    'Greet user': 'Hey, {{name}}',
    'No projects yet': 'It seems you don’t have any projects yet...',
    'First project info 1/2':
      'No problem. You can always start by making a new project. With a new project, you can start filling in tasks, milestones, and related people to your project to succeed in your product development goals.',
    'First project info 2/2':
      'Another way to start using VISDOM is to get an invitation to another existing project. Contact your project’s product owner to invite you in.',
    complexityValueTitle: 'Complexity / Value',
    customerStakesTitle: 'Client shares of the total value',
    'No ratings': 'No ratings',
    unratedTaskMessage: 'Waiting for ratings',
    'Optional message': 'Optional message',
    'Unselect default project': 'Unselect default project',
    'Overview header': '{{overviewType}} overview:',
    Client: 'Client',
    Contact: 'Contact',
    'Client value': 'Client value',
    'Tasks rated': 'Tasks rated',
    'Unrated tasks': 'Unrated tasks',
    'Remove task': 'Remove task',
    'Remove task warning':
      'Are you sure you want to remove <strong>{{name}}</strong>?',
    'Leave roadmap warning':
      'Are you sure you want to leave roadmap <strong>{{name}}</strong>?',
    'Leave roadmap warning last admin':
      'Cannot leave as the last Project Owner of roadmap. Delete the whole roadmap instead.',
    'Leave roadmap': 'Leave roadmap',
    'Go to my projects': 'Go to my projects',
    'Explore later?': 'Explore later?',
    'Go explore':
      '<strong>All done!</strong> You can now go explore your projects.',
    'Your email has now been verified':
      'Your email {{email}} has now been verified',
    'Email verified': 'Email verified',
    'valid email verification link info':
      'We have sent an email to <strong>{{email}}</strong> on <strong>{{sent}}</strong> for you to verify your email address using the verification link in the email.',
    'no email verification link info':
      'We will soon send an email to <strong>{{email}}</strong> for you to verify your email address using the verification link in the email.',
    'Email verification failed': 'Email verification failed',
    'Resend email': 'Resend email',
    'Wrong email address?': 'Wrong email address?',
    'Change address': 'Change address',
    'verify email before your journey':
      'Before your journey in Visdom, we need to verify your email address.',
    'Join project': 'Join project',
    'Join project info 1/2':
      'You have been invited to join <strong>{{name}}</strong> as <strong>{{type}}.</strong>',
    'Join project info 2/2': 'Join project now?',
    'Cancel joining project': 'Cancel joining project',
    'Cancel join project warning':
      'Are you sure you want to cancel joining this project?',
    'New project added': 'New project added',
    'New project added description':
      'Awesome! <strong>{{name}}</strong> has been added in your projects.',
    'No, thanks': 'No, thanks',
    'Project link invalid': 'Project link invalid',
    'Project link invalid info 1/2': 'Oops! Project link has gone bad.',
    'Project link invalid info 2/2':
      'Please request a new link from the person who invited you.',
    'OK!': 'OK!',
    'Log in with another account': 'Log in with another account',
    'Project link no access info 1/2':
      'You cannot join project with current account.',
    'Project link no access info 2/2':
      'Do you want to log in again with different email to join?',
    'Yes, log in again': 'Yes, log in again',
    Oops: 'Oops...',
    'Page not found description 1/2':
      'It seems we can’t find the page you’re looking for.',
    'Page not found description 2/2':
      'The link may be broken or the page may have been removed. If the link was typed in by hand, check the link for typo monsters!',
    'Go to Homepage': 'Go to Homepage',
    'Problems with Roadmapper?': 'Problems with Roadmapper?',
    'you can remove your account 1/2':
      'If you no longer need your account, you can remove your account.',
    'you can remove your account 2/2':
      'Note: Don’t remove your account if you just want to change your email address.',
    'Remove account': 'Remove account',
    'Task list tooltip':
      'Tasks are rated by client value and complexity. Rated tasks are used for planning and estimating future milestones by the PO.',
    'Planner complexityValue tooltip':
      'Roadmap shows a visual comparison of milestones’ total complexity and average value',
    'Planner customerStakes tooltip':
      'Values are calculated using the average value of tasks for a client.',
    'Planner milestones tooltip':
      'Milestones create your project’s <1>roadmap.</1>',
    'Planner weights tooltip':
      'Client weights affect the scaling of milestone`s total values in the roadmap graph. Weight of 0% will ignore the ratings of the client in question.',
    'Compare milestone durations with different estimates.':
      'Compare milestone durations with different estimates.',
    Realization: 'Realization',
    'system calculated average values over time':
      'system calculated average values over time.',
    Comparison: 'Comparison',
    'compare to another milestone': 'compare to another milestone.',
    Custom: 'Custom',
    'estimate with a custom value for work amount in working days':
      'estimate with a custom value for work amount in working days.',
    'config-jira-tooltip': 'Settings for JIRA for task imports',
    'config-trello-tooltip': 'Settings for TRELLO for task imports',
    'oauth-jira-tooltip': 'TBD',
    'oauth-trello-tooltip': 'TBD',
    'Personal auth token tooltip': 'TBD',
    'Taskmap-tooltip':
      'New synergy group can be created by dragging a task outside of existing groups.\n\nRelations can be deleted by right clicking them or hovering over and pressing backspace or delete.',
    'Forgot password': 'Forgot password?',
    'Remembered password': 'Remembered password?',
    'Forgot password subtitle':
      'No worries, enter your registered email address and we’ll send you a link to reset your password.',
    'Reset link sent': 'Reset link sent',
    'Resend link': 'Resend link',
    'Didn’t receive the email': 'Didn’t receive the email?',
    'Reset link sent successfully': 'Reset link sent successfully.',
    'Reset password': 'Reset password',
    'We have sent an email':
      'We have sent an email to <strong>{{email}}</strong> for you to reset your password',
    'Retry timer': 'Try again in {{time}}s',
    'Password set success':
      '<strong>All done!</strong> Your new password has been set.',
    'Token not found': 'Token not found.',
    'Token expired': 'Token expired.',
    'Error message': 'Error: {{error}}.',
    'Email service error': 'Email service error',
    'This email doesn’t exist': 'This email doesn’t exist',
    You: 'You',
    'deleted account': 'deleted account',
  },
};
